// import { captureException } from '@sentry/gatsby';

const getJsonFromLink = (setLottieJson, link) => {
  (async () => {
    try {
      const response = await fetch(link);
      const responseJson = await response.json();
      setLottieJson(responseJson);
    } catch (error) {
      console.log('lottie failure', error);
    }
  })();
};

export default getJsonFromLink;
